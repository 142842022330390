import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { FadeUp } from "../shared/transitions";
import BackgroundImage from "gatsby-background-image";

function PostNavigation({ next, previous }) {
  const renderPrev = () => {
    if (!previous) {
      return null;
    }
    const { slug, thumbnail, title } = previous;

    return (
      <Link
        to={`/blog/${slug}`}
        className="post__navigation-link post__navigation-link--right"
      >
        <BackgroundImage
          className="post__navigation-link__image"
          fluid={thumbnail.childImageSharp.fluid}
        />
        <div className="post__navigation-link__overlay">
          <Container>
            <Row>
              <Col>
                <div className="text-block">
                  <p>Previous Post</p>
                  <h2>{title}</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Link>
    );
  };
  const renderNext = () => {
    if (!next) {
      return null;
    }

    const { slug, thumbnail, title } = next;

    return (
      <Link
        to={`/blog/${slug}`}
        className="post__navigation-link post__navigation-link--left"
      >
        <BackgroundImage
          className="post__navigation-link__image"
          fluid={thumbnail.childImageSharp.fluid}
        />
        <div className="post__navigation-link__overlay">
          <Container>
            <Row>
              <Col>
                <div className="text-block">
                  <p>
                    <FadeUp>Next Post</FadeUp>
                  </p>
                  <h2>{title}</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Link>
    );
  };
  return (
    <div className="post__navigation">
      {renderPrev()}
      {renderNext()}
    </div>
  );
}

export default PostNavigation;
