import React, { useEffect, useState } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import SEO from "../src/components/shared/seo";
import { Helmet } from "react-helmet";
import { graphql, Link, navigateTo } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as moment from "moment";
import { Footer } from "../src/components/global/footer";
import ShareBox from "../src/components/global/share-box";
import PostNavigation from "../src/components/blog/post-navigation";
import {
  fadeUpCharacters,
  Expand,
  FadeIn,
  FadeInLeft,
  FadeInRight,
  FadeUp,
  FadeUpBox,
} from "../src/components/shared/transitions";
import { PoseGroup } from "react-pose";
import SplitText from "react-pose-text";
import { ParallaxBanner } from "react-scroll-parallax";
import BackgroundImage from "gatsby-background-image";

function blogPostDetail(props) {
  const { node: data, next, previous } = props.pathContext;
  const [animate, setAnimate] = useState(false);
  const {
    title,
    date,
    excerpt,
    tags,
    thumbnail,
    readingTime,
    seo,
    author: authorDetails,
  } = data;
  const { descriptionMeta, titleMeta, keywordsMeta, summaryMeta } = seo;
  const {
    name,
    thumbnail: authorThumbnail,
    title: authorTitle,
  } = authorDetails;
  const { body } = data;

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 200);
    // console.log(splitTitle(title));
    return () => {
      clearTimeout(timer);
    };
  });

  // const splitTitle = (characterArry: string[]) => {
  //   const splitString = String(characterArry).split(" ");
  //   let wordCount = 0;
  //   for (let i = 0; i < splitString.length; i++) {
  //     if (splitString.length === 5) {
  //       break;
  //     }

  //     if (wordCount <= 5 && splitString.length + wordCount !== 5) {
  //       splitString.splice(i, 0, "<br>");
  //       wordCount = 0;
  //     } else {
  //       wordCount++;
  //     }
  //   }

  //   console.log(splitString);
  // };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "Michael Johnson",
              "datePublished": "2020-03-09",
              "reviewBody": "Diviv is a solution based partner that delivers front and back end website solutions with innovation, professionalism and integrity. They are a great team and a pleasure to work with.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO
        title={titleMeta || title}
        keywords={keywordsMeta.join(", ")}
        description={descriptionMeta}
      />

      <div className="page-layout pb-0 pt-0">
        <BackgroundImage
          className="post__header-container"
          fluid={thumbnail.childImageSharp.fluid}
        >
          <div className="post__header-overlay"></div>
          <Container>
            <Row>
              <Col>
                <FadeInLeft pose={animate ? "enter" : "exit"} delay={600}>
                  <Link to="/blog" className="post__back">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 23.8 13.7"
                      xmlSpace="preserve"
                    >
                      <polyline
                        className="chevron-left"
                        points="0.9,12.8 11.9,1.8 22.9,12.8 "
                      />
                    </svg>
                    Back
                  </Link>
                </FadeInLeft>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col sm={12} md={12} lg={9}>
                <div className="post__header" key="test">
                  <div className="post__details">
                    <div className="post__author">
                      <Expand pose={animate ? "enter" : "exit"} duration={500}>
                        <div className="author__image">
                          <Image src={authorThumbnail} />
                        </div>
                      </Expand>

                      <div className="author__details">
                        <PoseGroup>
                          {animate && [
                            <FadeInLeft
                              duration={200}
                              delay={500}
                              key="fadeInRight1"
                            >
                              <div className="author__name">
                                Created By <span>{name}</span>
                              </div>
                            </FadeInLeft>,
                            <FadeInLeft
                              duration={200}
                              key="fadeInRight2"
                              delay={600}
                            >
                              <div className="author__title">{authorTitle}</div>
                            </FadeInLeft>,
                          ]}
                        </PoseGroup>
                      </div>
                    </div>
                    <FadeInRight
                      pose={animate ? "enter" : "exit"}
                      duration={500}
                    >
                      <p className="post__date">{moment(date).format("ll")}</p>
                    </FadeInRight>
                  </div>
                  {readingTime && (
                    <FadeIn duration={200} delay={700} key="fadeIn123">
                      <small className="post__reading-time">
                        Reading Time - {readingTime}
                      </small>
                    </FadeIn>
                  )}
                  <div style={{ overflow: "hidden" }}>
                    <h2 className="post__title">
                      <FadeUp pose={animate ? "visible" : "hidden"} delay={300}>
                        {title}
                      </FadeUp>
                    </h2>
                  </div>
                  <div style={{ overflow: "hidden" }}>
                    <h5 className="post__sub">
                      <FadeUp pose={animate ? "visible" : "hidden"} delay={700}>
                        {excerpt}
                      </FadeUp>
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>

        <ParallaxBanner
          className="post__banner"
          layers={[
            {
              image: thumbnail.childImageSharp.fluid.src,
              amount: 0.5,
            },
          ]}
        />
        <Container>
          <Row className="justify-content-center">
            <Col sm={12} md={12} lg={9}>
              <FadeIn duration={200} pose={animate ? "enter" : "exit"}>
                <div className="post__body">
                  <MDXRenderer>{body}</MDXRenderer>
                </div>
              </FadeIn>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={12} md={12} lg={9}>
              <div className="tag-list  pt-3">
                {tags &&
                  tags.map((tag, i) => (
                    <div key={i} className="tag">
                      {tag}
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ShareBox context={props.pathContext} inline />
            </Col>
          </Row>
        </Container>
      </div>
      <ShareBox context={data} />
      <PostNavigation previous={previous} next={next} />
      <Footer />
    </>
  );
}

export default blogPostDetail;

// export const query = graphql`
//   query($slug: String!) {
//     mdx(slug: { eq: $slug }) {
//       frontmatter {
//         title
//         thumbnail
//         excerpt
//         tags
//         date
//         author
//         seo {
//           descriptionMeta
//           titleMeta
//           keywordsMeta
//           summaryMeta
//         }
//       }
//       body
//     }
//   }
// `;
