import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";

function ShareBox({
  context,
  inline,
}: {
  context;
  inline?: boolean;
  children?;
}) {
  const { emailIcon, facebookIcon, linkedinIcon } = useStaticQuery(graphql`
    query {
      emailIcon: file(relativePath: { eq: "email-us-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facebookIcon: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linkedinIcon: file(relativePath: { eq: "linkedin-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const href = typeof window !== "undefined" ? window.location.href : "";

  return (
    <div
      className={`social-media__links--share share-box${
        inline ? "--inline" : ""
      }`}
    >
      <div className="social-media__links__title">Share</div>
      <div className="social-media__links__account">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${href}`}
          target="_blank"
          rel="noreferrer"
          className="social-icon__link"
        >
          <div className="icon">
            <img
              src={facebookIcon.childImageSharp.fluid.src}
              className="facebook"
              alt="Follow Diviv on Facebook"
              style={{ width: "25px" }}
            />
          </div>
        </a>
      </div>

      <div className="social-media__links__account">
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${href}&title=${context.title}&summary=${context.excerpt}&source=${href}`}
          target="_blank"
          rel="noreferrer"
          className="social-icon__link"
        >
          <div className="icon">
            <img
              src={linkedinIcon.childImageSharp.fluid.src}
              alt="Share this post on "
              style={{ width: "19px" }}
            />
          </div>
        </a>
      </div>
      <div className="social-media__links__account">
        <a
          href={`mailto:?&subject=Check out this blog post!&body=${href}`}
          target="_blank"
          rel="noreferrer"
          className="social-icon__link"
          style={{ height: "19px" }}
        >
          <div className="icon">
            <img
              src={emailIcon.childImageSharp.fluid.src}
              alt="Shere this by email"
              style={{ width: "19px" }}
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default ShareBox;
